import './App.css';


function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src="https://www.gravatar.com/avatar/04de4a38d70daa8c6ebdc2258fd0b99e?s=250" className="App-logo" alt="logo" />
        </header>
          
          <div className="socials">
            <a className="App-link" href="https://www.youtube.com/channel/UCnSz9gRFVUrdB81XsiSUHdQ" target="_blank" rel="noopener noreferrer">Youtube</a>
          </div>

        <section className="youtube">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/GXrVbUfDvdk" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
        </section>


          <div className="socials">
            <a className="App-link" href="https://www.twitter.com/comicsengland" target="_blank" rel="noopener noreferrer">Twitter</a>     
          </div>  
          <div className="socials">
            <a className="App-link" href="https://www.facebook.com/comicsengland" target="_blank" rel="noopener noreferrer">Facebook</a>
          </div>
          <div className="socials">    
              <a className="App-link" href="https://www.instagram.com/comicsengland" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
          <div className="socials">
              <a className="App-link" href="mailto:adam@comicsengland.co.uk" target="_blank" rel="noopener noreferrer">Email</a>
          </div>

      </div>
  );
}

export default App;